import { useTranslation } from "react-i18next";
import GetBackgroundColors from "@/lib/GetBackgroundColors/GetBackgroundColors";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    type SelectChangeEvent,
} from "@mui/material";

interface SelectBgColorProps {
    onChange?: (value: string) => void;
    value?: string;
}

const SelectBgColor = (props: SelectBgColorProps) => {
    const { t } = useTranslation("common", {
        keyPrefix: "background-colors",
    });

    const handleChange = (event: SelectChangeEvent<string>): void => {
        const value = event.target.value;
        props.onChange?.(value);
    };

    const bgColors = GetBackgroundColors();

    return (
        <FormControl sx={{ minWidth: 150 }}>
            <InputLabel id="select-bg-color-label">
                {t("background-color", "Achtergrondkleur")}
            </InputLabel>
            <Select
                labelId="select-bg-color-label"
                id="select-bg-color"
                label={t("background-color", "Achtergrond kleur")}
                value={props.value}
                onChange={handleChange}
            >
                {bgColors.map((bgColor) => (
                    <MenuItem key={bgColor.value} value={bgColor.value}>
                        {t(bgColor.label)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectBgColor;
