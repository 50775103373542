import { type ReactNode, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useGetAuthenticatedUserAuthoritiesSuspenseQuery } from "@bespeak/apollo";
import type { Scope } from "@/store/permissions";
import { usePermissionsStore } from "@/store/permissions";
import { skipToken } from "@apollo/client";

namespace PermissionsProvider {
    export interface Props {
        children: ReactNode;
    }
}

/**
 * @deprecated This should be moved to the user-configuration hook instead, as
 *  that query already provides the necessary information
 * @see UserConfigProvider
 */
export function PermissionsProvider(props: PermissionsProvider.Props) {
    const { isAuthenticated } = useAuth();

    const { data } = useGetAuthenticatedUserAuthoritiesSuspenseQuery(
        !isAuthenticated ? skipToken : {},
    );

    useEffect(() => {
        data &&
            usePermissionsStore.setState({
                scopes: data.getAuthoritiesForAuthenticatedUser as Scope[],
                ready: true,
            });
    }, [data]);

    return props.children;
}
