import { useState } from "react";

/**
 * This method is used to throw an error inside a hook.
 *
 * This is useful when you want to throw an error inside a hook, but you can't because it's a hook.
 * And throwing errors inside hooks can cause then to not be caught by the error boundary.
 *
 * Approved by Dan Abramov himself. {@link https://github.com/facebook/react/issues/14981#issuecomment-468460187}.
 *
 * @returns the method that fixes all of your problems when throwing an error inside a hook.
 */
export function useThrowInHook() {
    const [, setState] = useState();

    return function throwInHook(cb: () => any) {
        setState(cb);
    };
}

export default useThrowInHook;
