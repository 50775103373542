import { BuildingBlockBackground } from "@bespeak/apollo";

export const GetBackgroundColors = () => {
    const bgValues = Object.values(BuildingBlockBackground);
    const bgKeys = Object.keys(BuildingBlockBackground);

    const bgArray = bgValues
        .map((bgValue, index) => {
            return {
                value: bgValue,
                label: bgKeys[index].toLowerCase(),
            };
        })
        .filter((bg) => bg.value !== BuildingBlockBackground.NeutralDark);

    bgArray.sort((a, b) =>
        a.value === "DEFAULT" ? -1 : b.value === "DEFAULT" ? 1 : 0,
    );

    return bgArray;
};

export default GetBackgroundColors;
