const INVALID_PROGRAM = "invalid-program";
const UNAUTHORIZED_ERROR = "authorization-error";

export function mapErrors(error: Error) {
    const defaultName = "general-error";
    const defaultMessage = "page-not-found";

    switch (true) {
        case error.message.includes(
            "Variable 'programId' has an invalid value",
        ):
            return {
                name: INVALID_PROGRAM,
                message: INVALID_PROGRAM,
            };

        case error.name === "UnauthorizedError":
        case error.message.includes("UnauthorizedError"):
        case error.message.includes("Exception while fetching data") &&
            error.message.endsWith("Denied"):
            return {
                name: UNAUTHORIZED_ERROR,
                message: UNAUTHORIZED_ERROR,
            };

        default:
            return {
                name: defaultName,
                message: defaultMessage,
            };
    }
}

export default mapErrors;
