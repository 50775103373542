import { useGetEducationalProgramsQuery } from "@bespeak/apollo";

export function useGetEducationalPrograms() {
    const { data, loading } = useGetEducationalProgramsQuery({
        variables: { pagination: { page: 0, pageSize: 100 } },
        errorPolicy: "all",
    });

    return {
        loading,
        educationalPrograms:
            data?.getEducationalPrograms?.rows?.filter(Boolean) || [],
    };
}
