export namespace toggleClassNameInTag {}

export function toggleClassNameInTag(
    className: string,
    tag: React.MutableRefObject<HTMLElement>,
    active: boolean = true,
) {
    if (active) tag.current.classList.add(className);
    else tag.current.classList.remove(className);
}
