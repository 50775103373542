import React, { useState } from "react";
import css from "./UserMenu.module.scss";
import { PersonOutline } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogTrigger,
    Popover,
    OverlayArrow,
} from "react-aria-components";
import { Divider, Typography } from "@bespeak/ui";
import { useTranslation } from "react-i18next";
import GroupsDropdown from "@/components/molecules/GroupsDropdown/GroupsDropdown";
import ProgramDropdown from "@/components/molecules/ProgramDropdown/ProgramDropdown";
import { useMediaQuery } from "usehooks-ts";
import { Scope, usePermissionsStore } from "@/store/permissions";
import { Link } from "react-router-dom";
import { Button as UIButton, SearchIcon, CloseIcon } from "@bespeak/ui";
import { useContext } from "react";
import { AlgoliaSearchContext } from "@/provider/AlgoliaSearch";
import type { AlgoliaSearchContextType } from "@/provider/AlgoliaSearch/AlgoliaSearch.types";

export const UserMenu = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "main-menu.user-menu",
    });

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { tenantHasSearchKey, handleToggleSearchActive, searchActive } =
        useContext<AlgoliaSearchContextType>(AlgoliaSearchContext);

    const hasPermission = usePermissionsStore((state) => state.hasPermission);
    const hasGroupProgress = hasPermission(Scope.GROUP_PROGRESS);

    const hasSufficientGroupPermissions = [
        hasPermission(Scope.GROUPS_CREATE),
        hasPermission(Scope.GROUPS_UPDATE),
    ].includes(true);

    const optionTuples = (
        [
            ["groups", "/groups", hasSufficientGroupPermissions],
            ["products", "/profile/products"],
            ["log-out", "/logout"],
        ] as const
    ).filter(
        ([, , hasPermission]) =>
            typeof hasPermission !== "boolean" || hasPermission,
    );

    const mediaQueryDesktop = useMediaQuery("(min-width: 992px)");

    const handleItemClick = () => {
        setIsDialogOpen(false);
    };

    return (
        <div className={css.UserMenu}>
            {mediaQueryDesktop ? (
                <>
                    {tenantHasSearchKey ? (
                        <div className={css.UserMenuSearch}>
                            <UIButton
                                onClick={handleToggleSearchActive}
                                variant="text"
                                size="tiny"
                                color="secondary"
                            >
                                {searchActive ? <CloseIcon /> : <SearchIcon />}
                            </UIButton>
                        </div>
                    ) : null}

                    {hasGroupProgress ? <GroupsDropdown /> : null}
                    <ProgramDropdown />
                    <Divider
                        size={Divider.Size.Sm}
                        direction={Divider.Direction.Vertical}
                    />
                </>
            ) : null}

            {!mediaQueryDesktop && tenantHasSearchKey ? (
                <UIButton
                    onClick={handleToggleSearchActive}
                    variant="text"
                    size="tiny"
                    color="secondary"
                >
                    {searchActive ? <CloseIcon /> : <SearchIcon />}
                </UIButton>
            ) : null}

            <DialogTrigger isOpen={isDialogOpen} onOpenChange={setIsDialogOpen}>
                <Button className={css.Trigger}>
                    <Typography variant="body2">
                        <PersonOutline /> <span>{t("my-account")}</span>
                    </Typography>
                </Button>
                <Popover
                    className={css.Popover}
                    placement="bottom"
                    offset={-5}
                    crossOffset={-20}
                >
                    <OverlayArrow>
                        <svg
                            width={20}
                            height={20}
                            className={css.Arrow}
                            viewBox="0 0 12 12"
                        >
                            <path d="M0 0 L6 6 L12 0" />
                        </svg>
                    </OverlayArrow>
                    <Dialog>
                        {optionTuples.map(([label, path]) => (
                            <Link
                                key={path}
                                to={path}
                                className={css.Link}
                                onClick={handleItemClick}
                            >
                                <Typography variant="body1">
                                    {t(label)}
                                </Typography>
                            </Link>
                        ))}
                        {!mediaQueryDesktop ? <ProgramDropdown /> : null}
                    </Dialog>
                </Popover>
            </DialogTrigger>
        </div>
    );
};

export default UserMenu;
