import { useMemo } from "react";
import type { ErrorLink } from "@apollo/client/link/error";
import { onError } from "@apollo/client/link/error";
import { GraphQLError, NetworkError } from "@/errors";
import useThrowInHook from "@/lib/use-throw-in-hook";

export function useErrorLink() {
    const throwInHook = useThrowInHook();

    return useMemo(
        () =>
            onError(({ graphQLErrors, response, networkError }) => {
                if (graphQLErrors) {
                    console.error(
                        "errorLink:onError:graphQLErrors",
                        graphQLErrors,
                    );
                    throwInHook(() => {
                        graphQLErrors.forEach((e) => {
                            throw new GraphQLError(e.message);
                        });
                    });
                }
                if (graphQLErrors || response?.data) {
                    throwInHook(() => {
                        throw new NetworkError(graphQLErrors as any);
                    });
                } else if (networkError) {
                    console.error(
                        "errorLink:onError:networkError",
                        networkError,
                    );
                    throwInHook(() => {
                        throw new NetworkError(graphQLErrors as any);
                    });
                }
            }),
        [throwInHook],
    );
}

export { type ErrorLink };

export default useErrorLink;
