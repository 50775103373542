import { type RouteObject } from "react-router-dom";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { withAuthenticationRequired } from "react-oidc-context";
import GroupRequiredGuard from "@/router/middleware/GroupRequiredGuard";
import MainLayout from "@/components/templates/Layout/MainLayout";
import BubbleRouterError from "./bubble-router-errors";
import { withValidLicenseRequired } from "./with-valid-licence-required";

function Box(props: any) {
    return <div data-testid="app-wrap" {...props} />;
}

const AuthRequiredGuard = withAuthenticationRequired(Box);
const ValidLicenseRequiredGuard = withValidLicenseRequired(Box);

/**
 * Note that the id property is used to generate the breadcrumb label through
 * translations.
 */
export const routes: RouteObject[] = [
    {
        path: "/register",
        id: "register",
        lazy: () => import("@/components/pages/Register/Register"),
    },
    {
        path: "/toegang.org",
        lazy: () => import("@/components/pages/Toegang.org/ReceiveToken"),
    },
    {
        path: "/",
        id: "root",
        element: (
            <AuthRequiredGuard>
                <ValidLicenseRequiredGuard>
                    <GroupRequiredGuard>
                        <MainLayout />
                    </GroupRequiredGuard>
                </ValidLicenseRequiredGuard>
            </AuthRequiredGuard>
        ),
        ErrorBoundary: BubbleRouterError,
        children: [
            {
                path: "/",
                element: <Navigate to="/programs" replace={true} />,
            },
            {
                path: "/group",
                children: [
                    {
                        path: "/group",
                        lazy: () => import("@/components/pages/Group/Group"),
                    },
                ],
            },
            {
                path: "/groups",
                children: [
                    {
                        path: "/groups",
                        lazy: () => import("@/components/pages/Groups/Groups"),
                    },
                    {
                        path: "create",
                        lazy: () => import("@/components/pages/CreateGroup"),
                    },
                    {
                        path: ":groupId",
                        lazy: () => import("@/components/pages/EditGroup"),
                    },
                ],
            },
            {
                path: "/rapportage",
                children: [
                    {
                        id: "rapportage",
                        path: "/rapportage",
                        lazy: async () =>
                            import("@/components/pages/Rapportage"),
                    },
                ],
            },
            {
                path: "/naslag",
                children: [
                    {
                        id: "reference-card-overview",
                        path: "/naslag",
                        lazy: () =>
                            import(
                                "@/components/pages/ReferenceCardOverview/ReferenceCardOverview"
                            ),
                    },
                ],
            },
            {
                path: "/programs",
                children: [
                    {
                        id: "programs",
                        path: "/programs",
                        lazy: () =>
                            import("@/components/pages/Dashboard/Dashboard"),
                    },
                ],
            },
            {
                path: "/program",
                children: [
                    {
                        path: "/program/:programId",
                        id: "program",
                        lazy: () =>
                            import("@/components/pages/Program/Program"),
                    },
                    {
                        path: "/program/:programId/learning-path/:learningPathId",
                        id: "learning-path",
                        lazy: () =>
                            import(
                                "@/components/pages/LearningPath/LearningPath"
                            ),
                    },
                    {
                        path: "/program/:programId/learning-path/:learningPathId/module/:moduleId",
                        id: "module",
                        lazy: () => import("@/components/pages/Module/Module"),
                    },
                    {
                        path: "/program/:programId/learning-path/:learningPathId/module/:moduleId/didactic-tool/:didacticToolId",
                        id: "didactic-tool",
                        lazy: () =>
                            import(
                                "@/components/pages/DidacticTool/DidacticTool"
                            ),
                    },
                ],
            },
            {
                path: "/profile",
                children: [
                    {
                        path: "products",
                        lazy: () => import("@/components/pages/products"),
                    },
                ],
            },
        ],
    },
    {
        path: "/logout",
        children: [
            {
                id: "logout",
                path: "/logout",
                lazy: () => import("@/components/pages/Logout/Logout"),
            },
        ],
    },
    {
        path: "/logout/toegangorg",
        children: [
            {
                id: "logout-toegang",
                path: "/logout/toegangorg",
                lazy: () => import("@/components/pages/Toegang.org/Logout"),
            },
        ],
    },
];

export default createBrowserRouter(routes);
