import type { WhiteLabelConfig } from "@bespeak/app/src/types/WhiteLabelConfig";

export function injectScript({ script }: { script: string }): void;
export function injectScript({ src }: { src: string; async?: true }): void;
export function injectScript({
    script,
    src,
    async,
}: {
    script?: string;
    src?: string;
    async?: boolean;
}): void {
    const scriptTag = document.createElement("script");

    scriptTag.type = "text/javascript";

    if (script) scriptTag.innerHTML = script;

    if (src) scriptTag.src = src;

    if (async) scriptTag.async = async;

    document.body.appendChild(scriptTag);
}

export function injectScripts(tenant: WhiteLabelConfig) {
    if (tenant.name === "Beautylevel") {
        injectScript({
            script: `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "n67skl0diy");`,
        });

        injectScript({
            script: `(function(window, document, dataLayerName, id) {
window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString();f="; SameSite=Strict"}document.cookie=a+"="+b+d+f+"; path=/"}
var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
tags.async=!0,tags.src="https://bespeakbv.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
!function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
})(window, document, 'dataLayer', 'b84bd397-f493-4007-9915-76ca99a3d887');`,
        });

        injectScript({
            src: "https://www.bugherd.com/sidebarv2.js?apikey=lwruvy6ko2rgl2wcjwgpmw",
            async: true,
        });
    }
}
