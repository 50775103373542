import { useMemo, useEffect, useRef, type ReactNode, useState } from "react";
import Webfont from "webfontloader";
import { CssBaseline } from "@bespeak/ui";
import { ThemeContext } from "@bespeak/ui/src/Theme";
import { useGetThemeQuery } from "@bespeak/apollo";
import type { WhiteLabelConfig } from "@/types/WhiteLabelConfig";
import { useProgramStore } from "@/store/preferences";
import { useGetIsAuthenticated } from "@/lib/Auth";
import { injectCssIntoHeadTag } from "@/lib/injectCssIntoHeadTag";
import { toggleClassNameInTag } from "@/lib/toggleClassNameInTag";
import { useLoader } from "@/lib/useLoader";

import { FontSetEnum } from "@bespeak/schema/enums/font-set.enum";
import { BorderRadiusEnum } from "@bespeak/schema/enums/border-radius.enum";
import { NeutralColorVariantEnum } from "@bespeak/schema/enums/neutral-colors-variant.enum";
import { appThemeSchema } from "@bespeak/schema/schemas/app-theme.schema";

namespace ThemeProvider {
    export type Props = OldThemeProvider.Props & NewThemeProvider.Props;

    /**
     * @deprecated this should be replaced with the new theme provider once the redesign implementation is complete
     */
    export namespace OldThemeProvider {
        export interface Props {
            whiteLabelConfig: WhiteLabelConfig;
            children: ReactNode;
        }
    }

    export namespace NewThemeProvider {
        export interface Props {}
    }
}

/**
 * @deprecated this should be replaced with the new theme provider once the redesign implementation is complete
 */
export function OldThemeProvider({
    children,
    whiteLabelConfig,
}: ThemeProvider.OldThemeProvider.Props) {
    return (
        <ThemeContext.Provider value={whiteLabelConfig}>
            {children}
            <CssBaseline />
        </ThemeContext.Provider>
    );
}

export function NewThemeProvider() {
    const documentRef = useRef(document.documentElement);
    const [, startLoading, stopLoading] = useLoader();
    const [isAuthenticated] = useGetIsAuthenticated();
    const [fontsLoading, setFontsLoading] = useState(false);

    const { data: { theme: baseTheme } = {}, loading: baseThemeIsLoading } =
        useGetThemeQuery({
            skip: !isAuthenticated,
            onError,
        });

    const { value: programId } = useProgramStore();

    const {
        data: { theme: programTheme } = {},
        loading: programThemeIsLoading,
    } = useGetThemeQuery({
        skip: !isAuthenticated || !programId,
        variables: {
            programId,
        },
        onError,
    });

    /**
     * Loading state
     *
     * Provides the combined loading state in case anything is still busy that may
     * cause the looks of the app to be in turmoil.
     */
    const loading = useMemo(
        () => baseThemeIsLoading || programThemeIsLoading || fontsLoading,
        [baseThemeIsLoading, programThemeIsLoading, fontsLoading],
    );

    /**
     * Combined theme settings from the backend.
     *
     * The backend already does the merging, but in case we do not get a theme with overrides
     * then we still need to pick the theme that is not null.
     */
    const theme = useMemo(() => {
        const activeTheme = programTheme ?? baseTheme;
        return appThemeSchema.safeParse(activeTheme).data;
    }, [baseTheme, programTheme]);

    //
    // Apply the theme properties
    //

    useEffect(() => {
        if (theme) {
            // Brand Color Variables
            // ----------------------------
            // Here we just dump some arbitrary CSS into the head tag.

            injectCssIntoHeadTag("theme-variables", theme.brandColors);

            // Neutral Colors Variant
            // ----------------------------
            // We toggle .warm and .cold to the html tag to apply the color variant.

            toggleClassNameInTag(
                "warm",
                documentRef,
                theme.neutralColorsVariant === NeutralColorVariantEnum.WARM,
            );

            toggleClassNameInTag(
                "cold",
                documentRef,
                theme?.neutralColorsVariant === NeutralColorVariantEnum.COLD,
            );

            // Border radius
            // ----------------------------
            // We toggle .no-radius, .small-radius and .big-radius to the html tag to apply the border radius.

            toggleClassNameInTag(
                "no-radius",
                documentRef,
                theme.radiuses === BorderRadiusEnum.NONE,
            );

            toggleClassNameInTag(
                "small-radius",
                documentRef,
                theme.radiuses === BorderRadiusEnum.SMALL_RADIUS,
            );

            toggleClassNameInTag(
                "big-radius",
                documentRef,
                theme.radiuses === BorderRadiusEnum.BIG_RADIUS,
            );

            // Fonts
            // ----------------------------
            // In this case we are using the WebfontLoader library to load the fonts
            // and we are using the toggleClassNameInTag function to add the class to the html
            // tag to apply the font to the whole application.

            switch (theme.fonts) {
                case FontSetEnum.ATKINSON_LEXEND:
                    toggleClassNameInTag("atkinson-lexend", documentRef);
                    Webfont.load({
                        google: {
                            families: [
                                "Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700",
                                "Lexend:wght@100..900",
                            ],
                        },
                        loading() {
                            setFontsLoading(true);
                        },
                        active() {
                            setFontsLoading(false);
                        },
                    });
                    break;
                case FontSetEnum.ROBOTO_ROBOTOSLAB:
                    toggleClassNameInTag("roboto-robotoslab", documentRef);
                    break;
            }
        }
    }, [theme]);

    //
    // Because we want to avoid FOUC (Flash of Unstyled Content) and FOUT (Flash of Unstyled Text)
    // we are using the useLoader hook to show a loading spinner while the theme is being loaded.
    //

    useEffect(() => {
        if (loading) startLoading();
        else stopLoading();
    }, [loading, startLoading, stopLoading]);

    return null;
}

export function ThemeProvider({
    children,
    whiteLabelConfig,
}: ThemeProvider.Props) {
    return (
        <OldThemeProvider whiteLabelConfig={whiteLabelConfig}>
            <NewThemeProvider />
            {children}
        </OldThemeProvider>
    );
}

function onError(error: Error) {
    console.error(`Error while loading theme data`, error);
}
