import { z } from "zod";
import { isPast, isFuture } from "date-fns";
import { appProductSchema } from "./app-product.schema";

export const appLicenseSchema = z.object({
    id: z.string(),
    validUntil: z.any().transform((val) => {
        if (val === undefined || val === null) return null; // Transform undefined to null
        if (val instanceof Date) return val; // Date remains as is
        return new Date(val); // Coerce string to Date
    }),
    startAt: z.coerce.date(),
    product: appProductSchema,
});

export type AppLicense = z.infer<typeof appLicenseSchema>;

export const appLicenseActive = appLicenseSchema
    .pick({
        startAt: true,
        validUntil: true,
    })
    .superRefine(({ startAt, validUntil }, { addIssue }) => {
        if (isFuture(startAt))
            addIssue({
                code: z.ZodIssueCode.invalid_date,
                message: `${startAt} should be in the past`,
                path: ["startAt"],
            });

        if (validUntil && isPast(validUntil))
            addIssue({
                code: z.ZodIssueCode.invalid_date,
                message: `${validUntil} should be in the future`,
                path: ["validUntil"],
            });
    });

/**
 * Check if an array of licenses contains at least one valid license.
 *
 * Made resilient to invalid input to avoid crashing the app while waiting for a positive value.
 * For example, when the app is still loading the user configuration.
 *
 * @param licenses
 * @returns
 */
export function hasValidLicense(
    licenses: {
        startAt: Date | string;
        validUntil?: Date | string | null;
    }[] = [],
) {
    return Array.isArray(licenses)
        ? licenses.some(
              (license) => appLicenseActive.safeParse(license).success,
          )
        : false;
}

export function licenseIsActive(license: {
    startAt?: any;
    validUntil?: any;
}): boolean {
    return appLicenseActive.safeParse(license).success;
}

export function licenseNotActive(license: {
    startAt?: any;
    validUntil?: any;
}): boolean {
    return appLicenseActive.safeParse(license).success === false;
}

export default appLicenseSchema;
