export enum FontSize {
    Xs = "text-xs",
    Sm = "text-sm",
    Base = "text-base",
    Lg = "text-lg",
    Xl = "text-xl",
    TwoXl = "text-2xl",
    ThreeXl = "text-3xl",
}

export enum FontVariant {
    Span = "span",
    Div = "div",
    H1 = "h1",
    H2 = "h2",
    H3 = "h3",
    H4 = "h4",
    H5 = "h5",
    H6 = "h6",
    P = "p",
    Label = "label",
    Strong = "strong",
    Italic = "i",
    Li = "li",
}
