import { useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LicenseExpiredDialog from "@/components/organisms/license-expired-dialog";
import { userConfigContext } from "@/provider/user-config";
import { licenseNotActive } from "@bespeak/schema/schemas/app-license.schema";
import { hasValidLicense } from "@bespeak/schema/schemas/app-license.schema";
import { useDialogStack } from "@bespeak/ui";
import { useLicenseExpirationSeenMutation } from "@bespeak/apollo";

export interface WithValidLicenseRequiredOptions {
    onRedirecting?: () => JSX.Element;
}

export const withValidLicenseRequired = <P extends object>(
    Component: React.ComponentType<P>,
    options: WithValidLicenseRequiredOptions = { onRedirecting: () => <></> },
): React.FC<P> => {
    const displayName = `withValidLicenseRequired(${Component.displayName || Component.name})`;

    const C: React.FC<P> = (props) => {
        const userConfig = useContext(userConfigContext),
            redirectsRef = useRef(-1), // because strict mode...
            location = useLocation(),
            navigate = useNavigate(),
            licenses = userConfig?.licenses,
            isAtLicensePage = location.pathname === "/profile/products",
            validLicenses = hasValidLicense(licenses),
            shouldRender = isAtLicensePage || validLicenses,
            shouldRedirect = !isAtLicensePage && !validLicenses,
            placeholder = options.onRedirecting?.();

        useEffect(
            function redirectIfUserHasNoValidLicensesEffect() {
                if (shouldRedirect) {
                    if (redirectsRef.current > 0) {
                        console.warn(
                            `withValidLicenseRequired: Redirect loop averted`,
                        );
                        return;
                    }
                    redirectsRef.current++;
                    console.warn(
                        `withValidLicenseRequired: Can not find a valid license, redirecting to /profile/products`,
                    );
                    navigate("/profile/products");
                }
            },
            [navigate, shouldRedirect],
        );

        const dialogOpenRef = useRef(false),
            dialogStack = useDialogStack(),
            unseenExpiredLicenses = licenses
                ?.filter(licenseNotActive)
                .filter((license) => license.userExpirationSeenAt === null),
            [licenseSeen] = useLicenseExpirationSeenMutation();

        useEffect(
            function notifyUserWhenLicenseHasExpired() {
                if (dialogOpenRef.current) return;
                if (unseenExpiredLicenses?.length) {
                    dialogOpenRef.current = true;
                    dialogStack.push({
                        component: (
                            <LicenseExpiredDialog
                                products={unseenExpiredLicenses.map(
                                    (license) => license.product.name,
                                )}
                                onConfirm={() => {
                                    Promise.all(
                                        unseenExpiredLicenses.map(({ id }) =>
                                            licenseSeen({ variables: { id } }),
                                        ),
                                    );
                                    dialogStack.pop();
                                }}
                            />
                        ),
                    });
                }
            },
            [dialogStack, licenseSeen, unseenExpiredLicenses],
        );

        return shouldRender ? <Component {...props} /> : placeholder;
    };

    C.displayName = displayName;

    return C;
};
