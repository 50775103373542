import type { HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import css from "./DialogBase.module.css";

interface DialogBaseProps
    extends Pick<HTMLAttributes<HTMLDivElement>, "className" | "style"> {
    children: ReactNode;
    active?: boolean | null | undefined;
    static?: boolean | null | undefined;
    onClickOutside?: () => void;
    backdrop?: boolean | null | undefined;
}

export default function DialogBase({
    className,
    active,
    static: _static,
    backdrop,
    style,
    children,
}: DialogBaseProps) {
    return (
        <div
            data-testid="dialog"
            className={clsx(css.DialogBase, className, {
                [css.Static]: _static,
                [css.Backdrop]: backdrop,
            })}
            data-active={!!active}
            style={style}
        >
            {children}
        </div>
    );
}
