/** eslint-disable react/prop-types */
import { useTranslation } from "react-i18next";
import { Center, Spacer, Button } from "@bespeak/ui";
import { DefaultHeader } from "@bespeak/app/src/components/organisms/DefaultHeader/DefaultHeader";
import mapErrors from "./mapErrors";

export namespace ErrorTemplate {
    export type Props = {
        error?: unknown;
    };
}

export function ErrorTemplate({ error }: ErrorTemplate.Props) {
    const { t } = useTranslation("common", {
        keyPrefix: "not-found",
    });

    if (!(error instanceof Error)) {
        return;
    }

    const { message } = mapErrors(error);

    const handleBackToHome = () => {
        window.open("/", "_self");
    };

    return (
        <section data-test-id="not-found-page">
            <DefaultHeader
                headerHeight={DefaultHeader.Height.Sm}
                preTitle={t("title")}
                heading={t(message)}
            />
            <Spacer size="3xl" />
            <Center>
                <Button variant="text" onClick={handleBackToHome}>
                    {t("backToHome", "Klik hier om naar de homepage te gaan")}
                </Button>
            </Center>
        </section>
    );
}
