/**
 * @fileoverview A hook to keep track of the loading status of the application.
 *
 * This hook is used to set the loading status of the application. It will set
 * the `data-loaded` attribute on the document element to `true` or `false`, which
 * can be used to show or hide loading indicators.
 */

import { useEffect, useRef, useState } from "react";

/**
 * The attribute that will be set on the document element to indicate if the
 * document is loaded or not. This is also used in index.html to set the initial
 * value and set styles.
 */
const DOCUMENT_LOADED_ATTRIBUTE = "data-loaded";

/**
 * Keep track of the loading status of the application.
 */
export enum StatusEnum {
    Initializing = "INITIALIZING",
    Loading = "LOADING",
    Idle = "IDLE",
}

export namespace useLoader {
    export type Status = StatusEnum;
    export type IsLoading = boolean;
    export type StartLoading = () => void;
    export type StopLoading = () => void;
    export type ReturnTuple = [IsLoading, StartLoading, StopLoading, Status];
}

export function useLoader() {
    const documentRef = useRef(document.documentElement);
    const [status, setStatus] = useState<StatusEnum>(StatusEnum.Initializing);
    const isLoading = status === StatusEnum.Loading;

    const startLoading: useLoader.StartLoading = () => {
        setStatus(StatusEnum.Loading);
    };

    const stopLoading: useLoader.StopLoading = () => {
        setStatus(StatusEnum.Idle);
    };

    useEffect(() => {
        documentRef.current.setAttribute(
            DOCUMENT_LOADED_ATTRIBUTE,
            isLoading ? "false" : "true",
        );
    }, [isLoading]);

    return [
        isLoading,
        startLoading,
        stopLoading,
        status,
    ] satisfies useLoader.ReturnTuple;
}
