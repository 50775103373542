export namespace injectCssIntoHeadTag {
    export type Id = string;
    export type Css = string;
}

export function injectCssIntoHeadTag(
    id: injectCssIntoHeadTag.Id,
    css?: injectCssIntoHeadTag.Css,
) {
    if (!css) return;

    // Find the existing style element or create a new one
    const styleElement =
        document.getElementById(id) ?? document.createElement("style");

    // Set the innerHTML of the style element to the css
    styleElement.innerHTML = css;

    // If this is a new style element, set the id and append it to the head
    if (!styleElement.id) {
        styleElement.id = id;
        document.head.appendChild(styleElement);
    }
}
