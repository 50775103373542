import clsx from "clsx";
import css from "./LeftDesktopMenu.module.scss";
import type { MenuProps } from "@/components/organisms/MainMenu";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs.component";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Divider } from "@bespeak/ui";
import { Link } from "react-router-dom";
import useHistoryPath from "@/lib/useHistoryPath/useHistoryPath";

export const LeftDesktopMenu = ({
    links,
    program,
    learningPath,
    module,
    didacticTool,
}: MenuProps) => {
    const { t } = useTranslation("common", { keyPrefix: "main-menu" });
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const historyPath = useHistoryPath(pathname);

    const handleHistoryBack = () => {
        window.scrollTo(0, 0);
        navigate(historyPath);
    };

    return (
        <div className={css.LeftDesktopMenu}>
            {!module &&
                links.map(
                    (link: { href: string; label: string }, index: number) => (
                        <Link
                            key={index}
                            to={link.href}
                            className={clsx(css.MenuItem)}
                        >
                            {link.label}
                        </Link>
                    ),
                )}

            {module && (
                <>
                    <Button
                        onClick={() => handleHistoryBack()}
                        startAdornment={
                            <ArrowBackIcon
                                style={{ fontSize: "var(--base-font-size)" }}
                            />
                        }
                        variant="text"
                        color="surfaceOnBackground"
                        size="small"
                        className={css.Back}
                        style={{
                            fontSize: "var(--base-font-size)",
                            padding: 0,
                        }}
                    >
                        {t("back")}
                    </Button>
                    <Divider
                        size={Divider.Size.Sm}
                        direction={Divider.Direction.Vertical}
                    />
                    <Breadcrumbs
                        program={program!}
                        learningPath={learningPath}
                        module={module}
                        didacticTool={didacticTool}
                    />
                </>
            )}
        </div>
    );
};

export default LeftDesktopMenu;
