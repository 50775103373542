import css from "./DialogBody.module.css";

export type DialogBodyProps = {
    children: React.ReactNode;
    maxWidth?: string;
    className?: string;
};

export const DialogBody = ({ children, maxWidth }: DialogBodyProps) => {
    return (
        <div
            className={css.DialogBody}
            style={{ ...(maxWidth ? { [`--max-width`]: maxWidth } : null) }}
        >
            {children}
        </div>
    );
};

export default DialogBody;
