import { ApolloProvider } from "@apollo/client";
import useRetryLink from "./useRetryLink";
import useErrorLink from "./useErrorLink";
import useHttpLink from "./useHttpLink";
import useClient from "./useClient";

namespace GraphQLProvider {
    export interface Props {
        children: React.ReactNode;
    }

    export type GraphQLErrorType = {
        message: string;
        path?: string[];
        extensions?: {
            exception?: string;
        };
        locations?: {
            line: number;
            column: number;
        }[];
    };
}

export function GraphQLProvider(props: GraphQLProvider.Props) {
    const errorLink = useErrorLink();

    const httpLink = useHttpLink();

    const retryLink = useRetryLink();

    const client = useClient({
        errorLink,
        httpLink,
        retryLink,
    });

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
