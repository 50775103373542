type LabelConfig = Awaited<
    ReturnType<(typeof labelConfigs)[keyof typeof labelConfigs]>
>;

const labelConfigs = {
    beautylevel: async () => (await import("./beautylevel")).config,
    makemewitty: async () => (await import("./makemewitty")).config,
} as const;

const hrefRegExpLabelMap = new Map([
    [/beautylevel/, labelConfigs.beautylevel],
    [/visavi/, labelConfigs.beautylevel],
    [/makemewitty/, labelConfigs.makemewitty],
    [/redesign\.witty\.bespeak\.test/, labelConfigs.makemewitty],
    [/redesign\.witty\.bespeak\.acc/, labelConfigs.beautylevel],
]);

export async function labelConfig(href: string): Promise<LabelConfig> {
    for (const [hrefRegExp, label] of hrefRegExpLabelMap) {
        if (hrefRegExp.test(href)) {
            return await label();
        }
    }

    throw new Error("No label found");
}

export * from "./resolveOIDCSettings";
